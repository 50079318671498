import pick from 'lodash/pick'
import map from 'lodash/map'

import { commonCase } from '@/helpers/strings'

export function formatKeysForTable<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  Key extends keyof T
>(
  entity: T,
  pickedKeys: Key[]
): Array<{ key: Key; value: T[Key]; label: string }> {
  const picked = pick(entity, pickedKeys)  
  
  const updatedPickedArr = []

  if (!entity.is_promotional_admin_fee){ 
    const updatedPicked = {    
      ...picked,
      effective_admin_fee: entity.admin_fee 
    }
    return map(updatedPicked, (value, key) => ({
      key: key as Key,
      value,
      label: commonCase(key, true),
    }))
  } else {
    const updatedPicked = {    
      ...picked,
      admin_profit: entity.promotional_admin_fee 
    }
    return map(updatedPicked, (value, key) => ({
      key: key as Key,
      value,
      label: commonCase(key, true),
    }))
  }

  // return map(picked, (value, key) => ({
  //   key: key as Key,
  //   value,
  //   label: commonCase(key, true),
  // }))
}
